import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSDK } from "@metamask/sdk-react";
import { ethers } from "ethers";
import config from "../config";
import { abi } from "../contract";
import { bigIntToNumber, toPercent } from "../utils/numbers";
import { setupNetwork } from "../utils/network";

export type Project = {
  uid?: number;
  creator: string;
  username: string;
  title: string;
  description: string;
  img: string;
  amount: number;
  balance: number;
  backers: number;
  detailsUrl: string;
  percent?: string;
  isOwner?: boolean;
};

let browserProvider: ethers.BrowserProvider;
let webSocketProvider: ethers.WebSocketProvider;
let contract: ethers.Contract;

export function Projects() {
  const { connected, provider: ethereum } = useSDK();
  const [projects, setProjects] = useState<Project[]>([]);

  // Methods
  const getContract = async () => {
    if (!browserProvider) browserProvider = new ethers.BrowserProvider(ethereum as any, "any");
    if (!contract) contract = new ethers.Contract(config.contractAddress, abi, await browserProvider.getSigner());
    return contract;
  };

  const loadProjects = async () => {
    try {
      await setupNetwork(ethereum, config);
      const contract = await getContract();

      const projects: Project[] = [];
      const items = await contract.getAllCampaigns();

      for (let i = 0; i < items.length; i++) {
        const p: Project = {
          uid: i,
          creator: items[i].creator,
          username: items[i].username,
          title: items[i].title,
          description: items[i].description,
          img: items[i].img,
          amount: items[i].amount,
          balance: items[i].balance,
          detailsUrl: items[i].detailsUrl,
          backers: items[i].backers,
        };
        p.percent = toPercent(bigIntToNumber(p.balance), bigIntToNumber(p.amount));
        projects.push(p);
      }

      console.log("=> setting projects", projects);
      setProjects(projects.reverse());
    } catch (err) {
      console.error("@loadProjects", err);
    }
  };

  // Effects
  useEffect(() => {
    console.log("#connected", connected);
    if (!connected) return;
    console.log("#load...");
    loadProjects();
  }, [connected]);

  // Render
  if (!connected) {
    return (
      <section className="hero is-warning">
        <div className="hero-body">
          <p className="title has-text-centered">Please connect your wallet in order to see the projects.</p>
        </div>
      </section>
    );
  }

  return (
    <>
      <section className="hero">
        <div className="hero-body is-flex is-justify-content-space-between">
          <h1 className="title">Latest projects</h1>
          <div className="select">
            <select defaultValue={""}>
              <option value={""}>All projects</option>
              <option value={"my"}>My projects</option>
            </select>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="columns is-multiline ">
          {projects.length > 0 &&
            projects.map((p: Project) => (
              <div key={p.uid} className="column is-one-third p-6">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={p.img} alt="proj" />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="media">
                      <div className="media-left">
                        <figure className="image is-48x48">
                          <img src="/images/user.png" alt="user" />
                        </figure>
                      </div>
                      <div className="media-content">
                        <p className="title is-4">{p.username}</p>
                        <p className="subtitle is-6">@{p.username}</p>
                      </div>
                    </div>

                    <div className="content">
                      <h4 className="title is-4">{p.title}</h4>
                      <div className="is-flex is-justify-content-space-between">
                        <div>
                          <span className="has-text-weight-bold">{ethers.formatEther(p.balance)}</span>
                          <span className="ml-1">{config.currency} raised</span>
                        </div>
                        <span>{p.percent}%</span>
                      </div>
                      <progress className="progress is-success" value={p.percent} max="100">
                        {p.percent}
                      </progress>
                      <Link to={`/projects/view/${p.uid}`} className="button is-success">
                        Fund this project
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
