export function Howto() {
  return (
    <div className="m-6 box">
      <h1 className="title is-1">🛠️ How to</h1>
      <h4 className="title is-4">
        In order to interact with this platform you need to fund your wallet with some `MOON` tokens.
      </h4>
      <div className="content is-medium">
        <ul>
          <li>
            ✅ Make sure you have <strong>Metamask</strong> extension installed.
          </li>
          <li>
            ✅ Click on <strong>CONNECT WALLET</strong> button from the navigation bar.
          </li>
          <li>
            ✅ Check if you are connected to the correct network. (in Metamask it should appear{" "}
            <strong>DYM Moon Rollapp</strong>)
          </li>
          <li>
            ✅ Go to the
            <a className="ml-1 mr-1" href="https://discord.com/channels/956961633165529098/1143231362468434022">
              <strong>foopyland-faucet</strong>
            </a>
            and get some <strong>Moon</strong> tokens:
            <blockquote>$request *your-address* neomoon_1787516-1</blockquote>
          </li>
          <li>✅ Check Metamask and once you have the tokens, you can start your own crowd-funding campaigns.</li>
        </ul>
      </div>
      <h4 className="title is-4 p-3">Go out there and make your ideas a reality!</h4>
    </div>
  );
}
