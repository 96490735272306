import { useForm, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSDK } from "@metamask/sdk-react";
import { ethers } from "ethers";
import config from "../config";
import { abi } from "../contract";
import { useEffect, useState } from "react";
import { Project } from ".";
import { bigIntToNumber, toPercent } from "../utils/numbers";
import { setupNetwork } from "../utils/network";

type Inputs = {
  amount: number;
};

let browserProvider: ethers.BrowserProvider;
let contract: ethers.Contract;

export function ViewProject() {
  const params = useParams();
  console.log("#params", params);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log("# Submitting...");
    contribute(data);
  };

  const { connected, provider: ethereum, account } = useSDK();
  const [project, setProject] = useState<Project>();
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<boolean>(false);

  // Methods
  const getContract = async () => {
    if (!browserProvider) browserProvider = new ethers.BrowserProvider(ethereum as any, "any");
    if (!contract) contract = new ethers.Contract(config.contractAddress, abi, await browserProvider.getSigner());
    return contract;
  };

  const loadProjects = async () => {
    try {
      await setupNetwork(ethereum, config);
      const contract = await getContract();

      const projects: Project[] = [];
      const items = await contract.getAllCampaigns();

      for (let i = 0; i < items.length; i++) {
        const p: Project = {
          uid: i,
          creator: items[i].creator,
          username: items[i].username,
          title: items[i].title,
          description: items[i].description,
          img: items[i].img,
          amount: items[i].amount,
          balance: items[i].balance,
          detailsUrl: items[i].detailsUrl,
          backers: items[i].backers,
        };
        p.percent = toPercent(bigIntToNumber(p.balance), bigIntToNumber(p.amount));
        p.isOwner = p.creator.toLowerCase() == account?.toLocaleLowerCase();
        projects.push(p);
      }

      const projectId = Number(params.projectId);
      const currentProject = projects[projectId];
      setProject(currentProject);

      console.log("=> setting project", projects);
    } catch (err) {
      console.error("@loadProjects", err);
    }
  };

  const contribute = async (inputs: Inputs) => {
    try {
      const contract = await getContract();
      const projectId = Number(params.projectId);

      const tx = await contract.contribute(projectId, {
        value: ethers.parseEther(inputs.amount.toString()),
        gasLimit: 100000,
      });
      await tx.wait();
      console.log("# tx wait", tx);

      reset();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 7500);
      loadProjects();
    } catch (err) {
      console.error(err);
      setError((err as any).message);
      setTimeout(() => {
        setError(undefined);
      }, 7500);
    }
  };

  const withdraw = async () => {
    try {
      await setupNetwork(ethereum, config);
      const contract = await getContract();
      const projectId = Number(params.projectId);

      const tx = await contract.withdraw(projectId, { gasLimit: 100000 });
      await tx.wait();
      console.log("# tx wait", tx);

      console.log("=> withdraw");
    } catch (err) {
      console.error("@withdraw", err);
      setError((err as any).message);
      setTimeout(() => {
        setError(undefined);
      }, 7500);
    }
  };

  // Effects
  useEffect(() => {
    console.log("#connected", connected);
    if (!connected) return;
    console.log("#load...");
    loadProjects();
  }, [connected]);

  // Render
  if (!connected) {
    return (
      <section className="hero is-warning">
        <div className="hero-body">
          <p className="title has-text-centered">Please connect your wallet in order to see the projects.</p>
        </div>
      </section>
    );
  }

  if (!project) {
    return (
      <section className="hero is-warning">
        <div className="hero-body">
          <p className="title has-text-centered">Loading project...</p>
        </div>
      </section>
    );
  }

  return (
    <div className="container mt-5" style={{ backgroundColor: "white", borderRadius: 16 }}>
      {error && <div className="notification is-danger mt-1">⛔️ {error}</div>}
      {success && (
        <div className="notification is-success">
          <h4 className="title is-4">💫 Thank you for funding this project!</h4>
        </div>
      )}

      <div className="columns p-4">
        <div className="column">
          <figure className="image is-4by3">
            <img src={project.img} alt="proj" style={{ borderRadius: 4 }} />
          </figure>
        </div>
        <div className="column">
          <h1 className="title is-1">{project.title}</h1>
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img src="/images/user.png" alt="user" />
              </figure>
            </div>
            <div className="media-content">
              <p className="title is-4">{project.username}</p>
              <p className="subtitle is-6">@{project.username}</p>
            </div>
          </div>
          <hr />
          <div className="columns has-text-centered mt-5" style={{ paddingLeft: 32, paddingRight: 32 }}>
            <div className="column mr-2" style={{ backgroundColor: "#edf9f3", borderRadius: 8 }}>
              <span className="title is-4 has-text-weight-bold">
                {ethers.formatEther(project.amount)} {config.currency}
              </span>
              <p className="has-text-weight-bold">Pledged</p>
            </div>
            <div className="column ml-2" style={{ backgroundColor: "#edf9f3", borderRadius: 8 }}>
              <span className="title is-4 has-text-weight-bold">{bigIntToNumber(project.backers)}</span>
              <p className="has-text-weight-bold">Backers</p>
            </div>
          </div>

          <div className="is-flex is-justify-content-space-between mt-5">
            <div>
              <span className="title is-4">{ethers.formatEther(project.balance)}</span>
              <span className="title is-4 ml-1">{config.currency} raised</span>
            </div>
            <span className="title is-4 ml-1">{project.percent}%</span>
          </div>

          <progress className="progress is-success" value={project.percent} max="100">
            {project.percent}
          </progress>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field has-addons">
              <div className="control">
                <input
                  className="input"
                  type="number"
                  defaultValue=""
                  placeholder={`eg. 500 ${config.currency}`}
                  {...register("amount", { required: true, min: 1 })}
                />
                {errors.amount && <p className="help is-danger">This field is required</p>}
              </div>
              <div className="control">
                <button type="submit" className="button is-success">
                  Fund this project
                </button>
                {project.isOwner && (
                  <button type="button" className="button is-success ml-2" onClick={() => withdraw()}>
                    Claim your funding
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="block p-3">{project.description}</div>
      {project.detailsUrl && (
        <div className="p-3">
          <iframe width={"100%"} height={"600px"} title="project-description" src={project.detailsUrl} />
        </div>
      )}
    </div>
  );
}
