export type Config = {
  currency: string;
  currencyName: string;
  contractAddress: string;
  webSockerUrl: string;
  networkName: string;
  rpcUrl: string;
  chainId: string;
};

const config: Config = {
  currencyName: "Moon",
  currency: "MOON",

  // Live
  contractAddress: "0xC321cc0067367f553D7529eB11b6d28EA9b8433b",

  // Local
  // contractAddress: "0xE2f04f80FD0A8A037fEcB0d20DE94aDf5907F2b9",

  webSockerUrl: "ws://127.0.0.1:7545",
  networkName: "DYM Moon Rollapp",
  rpcUrl: "https://dymrollapp-jsonrpc.coanda.live",
  chainId: "0x1b467c",
};

export default config;
