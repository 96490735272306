import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { MetaMaskProvider } from "@metamask/sdk-react";
import { MetaMaskUIProvider } from "@metamask/sdk-react-ui";
import "./styles/index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <HashRouter>
      <MetaMaskProvider
        debug={true}
        sdkOptions={{
          checkInstallationImmediately: false,
          dappMetadata: {
            name: "Found Me App",
            url: window.location.host,
          },
        }}
      >
        <App />
      </MetaMaskProvider>
      {/* <MetaMaskUIProvider
        sdkOptions={{
          dappMetadata: {
            name: "Found Me App",
            url: window.location.host,
          },
          checkInstallationImmediately: false,
        }}
      >
        <App />
      </MetaMaskUIProvider> */}
    </HashRouter>
  </React.StrictMode>
);
