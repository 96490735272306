import { Config } from "../config";

export const setupNetwork = async (ethereum: any, config: Config) => {
  if (!ethereum) throw new Error("Ethereum empty");

  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: config.chainId }],
    });
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: config.chainId,
              chainName: config.networkName,
              rpcUrls: [config.rpcUrl],
              nativeCurrency: {
                name: config.currencyName,
                symbol: config.currency,
                decimals: 18,
              },
            },
          ],
        });
      } catch (addError: any) {
        throw new Error("Ethereum wallet_addEthereumChain", addError);
      }
    } else {
      throw new Error("other erroOmChain", switchError);
    }
  }
};
