export const bigIntToNumber = (bigInt: any): number => {
  const myBigInt = BigInt(bigInt);
  const n = Number(myBigInt);
  return n;
};

export const toPercent = (current: number, total: number): string => {
  if (!current || !total) return "0";
  const res = (current * 100) / total;
  return res.toFixed(2);
};

export const toUnit = (val: number): number => {
  return val * 1000000000000000000;
};
