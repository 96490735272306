import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSDK } from "@metamask/sdk-react";
import { ethers } from "ethers";
import config from "../config";
import { abi } from "../contract";
import { setupNetwork } from "../utils/network";

type Inputs = {
  username: string;
  title: string;
  description: string;
  img: string;
  amount: number;
  detailsUrl: string;
};

let browserProvider: ethers.BrowserProvider;
let contract: ethers.Contract;

export function Create() {
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<boolean>(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log("# Submitting...");
    createProject(data);
  };

  const { connected, provider: ethereum, account } = useSDK();

  // Methods
  const getContract = async () => {
    if (!browserProvider) browserProvider = new ethers.BrowserProvider(ethereum as any, "any");
    if (!contract) contract = new ethers.Contract(config.contractAddress, abi, await browserProvider.getSigner());
    return contract;
  };

  const createProject = async (inputs: Inputs) => {
    try {
      await setupNetwork(ethereum, config);
      const contract = await getContract();

      const tx = await contract.createCampaign(
        inputs.username,
        inputs.title,
        inputs.description,
        inputs.img,
        inputs.detailsUrl,
        ethers.parseEther(inputs.amount.toString())
      );
      await tx.wait();
      console.log("# tx wait", tx);

      reset();
      setSuccess(true);
    } catch (err) {
      console.error(err);
      setError((err as any).message);
      setTimeout(() => {
        setError(undefined);
      }, 7500);
    }
  };

  // Effects
  useEffect(() => {
    console.log("#connected", connected);
    if (!connected) return;
  }, [connected]);

  // Render
  if (!connected) {
    return (
      <section className="hero is-warning">
        <div className="hero-body">
          <p className="title has-text-centered">Please connect your wallet in order to see the projects.</p>
        </div>
      </section>
    );
  }

  return (
    <>
      <section className="hero page-title-area">
        <div className="hero-body">
          <p className="title">Create a new project</p>
        </div>
      </section>

      <div className="container pb-6">
        {error && <div className="notification is-danger">⛔️ {error}</div>}
        {success && (
          <div className="notification is-success">
            <h4 className="title is-4">💫 Project created successfully.</h4>
          </div>
        )}
        <div className="columns is-justify-content-center">
          <div className="column is-one-third card">
            <h1 className="title">💈 Create a new project</h1>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <label className="label">Your username:</label>
                <div className="control">
                  <input
                    className="input"
                    placeholder="eg. dymuser"
                    defaultValue=""
                    {...register("username", { required: true })}
                  />
                  {errors.username && <p className="help is-danger">This field is required</p>}
                </div>
              </div>

              <div className="field">
                <label className="label">Project title:</label>
                <div className="control">
                  <input
                    className="input"
                    placeholder="eg. My Project/ My charity fund"
                    defaultValue=""
                    {...register("title", { required: true })}
                  />
                  {errors.title && <p className="help is-danger">This field is required</p>}
                </div>
              </div>

              <div className="field">
                <label className="label">Project description:</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    {...register("description", { required: true })}
                    placeholder="eg. some project details"
                  ></textarea>

                  {errors.description && <p className="help is-danger">This field is required</p>}
                </div>
              </div>

              <div className="field">
                <label className="label">Project image (full url):</label>
                <div className="control">
                  <input
                    className="input"
                    defaultValue=""
                    placeholder="https://img-url.png"
                    {...register("img", { required: true })}
                  />
                  {errors.img && <p className="help is-danger">This field is required</p>}
                </div>
              </div>

              <div className="field">
                <label className="label">Target amount (CCC):</label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    defaultValue=""
                    placeholder="eg. 500 CCC"
                    {...register("amount", { required: true, min: 1 })}
                  />
                  {errors.amount && <p className="help is-danger">This field is required</p>}
                </div>
              </div>

              <div className="field">
                <label className="label">Extenal project details - optional (full url):</label>
                <div className="block">This is an url that will be loaded in an `iframe` in the project page</div>
                <div className="control">
                  <input
                    className="input"
                    defaultValue=""
                    placeholder="https://img-url.png"
                    {...register("detailsUrl")}
                  />
                  {errors.detailsUrl && <p className="help is-danger">This field is required</p>}
                </div>
              </div>

              <div className="field is-grouped">
                <div className="control">
                  <button className="button is-link" type="submit">
                    Create project
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
