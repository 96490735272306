import React, { useEffect, useState } from "react";
import { Routes, Route, Outlet, Link, useParams } from "react-router-dom";
import { Home, Projects, Create, ViewProject, Howto } from "./routes";
import { useSDK } from "@metamask/sdk-react";
import { MetaMaskButton } from "@metamask/sdk-react-ui";
import config from "./config";
import { setupNetwork } from "./utils/network";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="projects" element={<Projects />} />
        <Route path="projects/create" element={<Create />} />
        <Route path="projects/view/:projectId" element={<ViewProject />} />
        <Route path="howto" element={<Howto />} />

        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const [account, setAccount] = useState<string>();
  const { sdk, connected, connecting, provider: ethereum } = useSDK();

  const connect = async () => {
    try {
      const accounts: any = await sdk?.connect();
      setAccount(accounts?.[0]);
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
  };

  const setNetwork = async () => {
    try {
      await setupNetwork(ethereum, config);
      console.log("=> network ready");
    } catch (error) {
      console.log("=> network error", error);
    }
  };

  // Effects
  useEffect(() => {
    if (!account) return;
    setTimeout(() => {
      setNetwork();
    }, 2500);
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-brand">
          <Link className="navbar-item" to={"/"}>
            <img src="/images/logo.svg" height="28" className="ml-6" alt="home" />
            <h1 className="title ml-3">DYM FUND ME</h1>
          </Link>
        </div>
        <div id="navMenu" className="navbar-menu ml-6">
          <div className="navbar-start">
            <Link to="/projects" className="navbar-item has-text-weight-bold">
              Projects
            </Link>
            <Link to="/projects/create" className="navbar-item has-text-weight-bold">
              Create yours
            </Link>
            <Link to="/howto" className="navbar-item has-text-weight-bold">
              How to use this app
            </Link>
          </div>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            {/* <MetaMaskButton theme={"dark"} color="white"></MetaMaskButton> */}
            {!connected && (
              <div className="buttons">
                <a className="button is-light has-text-weight-bold mr-6" onClick={connect}>
                  ⚪️ CONNECT WALLET
                </a>
              </div>
            )}
            {connecting && <h6 className="title is-6 mr-6">🟡 Connecting</h6>}
            {connected && <h6 className="title is-6 mr-6">🟢 Connected</h6>}
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
}

function NoMatch() {
  return (
    <section className="hero is-fullheight-with-navbar">
      <div className="hero-body">
        <p className="title">Page not found</p>
      </div>
    </section>
  );
}
