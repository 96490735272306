import { Link } from "react-router-dom";

export function Home() {
  return (
    <section className="hero is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="columns is-align-items-center">
          <div className="column is-two-thirds">
            <p className="title is-1">DYM Crowdfund Hub</p>
            <p className="title is-3">Fueling aspirations, block by block</p>
            <Link to="/projects" className="title button is-link">
              Explore projects
            </Link>
          </div>
          <div className="column is-one-third p-3 has-background-white " style={{ borderRadius: 16 }}>
            <img src="/images/home.jpg" height="1" alt="home" />
          </div>
        </div>
      </div>
    </section>
  );
}
